const API_V1 = 'v1';
const API_V2 = 'v2';
const API_V2_2 = 'v2_2';
const API_V3 = 'v3';

const GET = 'GET';
const POST = 'POST';
const DELETE = 'DELETE';
const PUT = 'PUT';
const PATCH = 'PATCH';

export default {
    // 스윙비에 쓰이는 모든 API들 여기에 정의
    SESSION: { url: `/api/${API_V2}/session`, method: GET },
    MAIL: { url: `/api/${API_V1}/mail`, method: POST },
    REMOVE_SAMPLE: { url: `/api/${API_V1}/company/removeSample`, method: GET },

    // Newsfeed
    NEWSFEEDS: { url: `/api/${API_V1}/newsfeeds`, method: GET },
    CREATE_FEED: { url: `/api/${API_V1}/newsfeeds`, method: POST },
    UPDATE_FEED: { url: `/api/${API_V1}/newsfeeds/{0}`, method: POST },
    DELETE_FEED: { url: `/api/${API_V1}/newsfeeds/{0}`, method: DELETE },

    LIKE: { url: `/api/${API_V1}/newsfeeds/{0}/emoji`, method: POST },
    CANCEL_LIKE: { url: `/api/${API_V1}/newsfeeds/{0}/emoji`, method: DELETE },

    COMMENTS: { url: `/api/${API_V1}/newsfeeds/{0}/comments`, method: GET },
    CREATE_COMMENT: { url: `/api/${API_V1}/newsfeeds/{0}/comments`, method: POST },
    UPDATE_COMMENT: { url: `/api/${API_V1}/newsfeeds/{0}/comments/{1}`, method: PUT },
    DELETE_COMMENT: { url: `/api/${API_V1}/newsfeeds/{0}/comments/{1}`, method: DELETE },
    EVENTS: { url: `/api/${API_V1}/events`, method: GET },

    // Announcement
    ANNOUNCEMENTS: { url: `/api/${API_V1}/announcements`, method: GET },
    ANNOUNCEMENT: { url: `/api/${API_V1}/announcements/{0}`, method: GET },
    CREATE_ANNOUNCEMENT: { url: `/api/${API_V1}/announcements`, method: POST },
    UPDATE_ANNOUNCEMENT: { url: `/api/${API_V1}/announcements/{0}`, method: PATCH },
    DELETE_ANNOUNCEMENT: { url: `/api/${API_V1}/announcements/{0}`, method: DELETE },

    // People
    USERS: { url: `/api/${API_V2}/people/users`, method: GET },
    USERS_LIST: { url: `/api/${API_V2}/people/users/view-people`, method: GET },
    WHOLE_USERS_LIST: { url: `/api/${API_V2}/people/users/manage-hiring`, method: GET },
    REPORTING: { url: `/api/${API_V1}/people/reporting`, method: GET },
    JOB_TITLES: { url: `/api/${API_V1}/people/jobTitles`, method: GET },
    CREATE_JOB_TITLE: { url: `/api/${API_V1}/people/jobTitles`, method: POST },
    UPDATE_JOB_TITLE: { url: `/api/${API_V1}/people/jobTitles/{0}`, method: PUT },
    DELETE_JOB_TITLE: { url: `/api/${API_V1}/people/jobTitles/{0}`, method: DELETE },

    FILE_TOKEN: { url: `/api/files/${API_V1}/file/fileToken`, method: GET },
    PEOPLE: { url: `/api/${API_V1}/people`, method: GET },
    TEAM_FILTERS: { url: `/api/${API_V1}/people/teamFilters`, method: GET },
    MANAGER: { url: `/api/${API_V1}/people/users/{0}/manager`, method: GET },

    REPORT_TO: { url: `/api/${API_V1}/people/users/{0}/reportTo`, method: POST },

    // Timeoff
    TIMEOFFS: { url: `/api/leave/request/${API_V2}/requests`, method: GET },
    REMAIN_DAYS: { url: `/api/leave/request/${API_V2}/remainDays`, method: GET },
    CREATE_TIMEOFF: { url: `/api/leave/request/${API_V2}`, method: POST },
    UPDATE_TIMEOFF: { url: `/api/leave/request/${API_V2}/{0}`, method: PUT }, // {0}=leaveRequestId
    DELETE_TIMEOFF: { url: `/api/leave/request/${API_V2}/{0}`, method: DELETE }, // {0}=leaveRequestId
    GET_USER_SPECIFIC_APPROVERS: {
        url: `/api/leave/settings/approver/user-specific`,
        method: GET,
    },

    TIMEOFFS_MANAGE: { url: `/api/leave/request/${API_V2}/approvals`, method: GET },
    TIMEOFFS_APPROVALS: { url: `/api/leave/request/${API_V3}/approvals`, method: POST },

    TIMEOFF_TYPE_USERS: {
        url: `/api/${API_V1}/company/timeoffTypesAndUsers/{0}`,
        method: GET,
    }, // deprecated
    CREATE_TIMEOFF_TYPE: { url: `/api/${API_V1}/company/{0}/timeoffTypes`, method: POST }, // deprecated
    UPDATE_TIMEOFF_TYPE: {
        url: `/api/${API_V1}/company/{0}/timeoffTypes/{1}`,
        method: PUT,
    }, // deprecated
    DELETE_TIMEOFF_TYPE: {
        url: `/api/${API_V1}/company/{0}/timeoffTypes/{1}`,
        method: DELETE,
    }, // deprecated

    CREATE_TIMEOFF_TYPE_POLICY: {
        url: `/api/${API_V1}/company/timeoffTypes/{0}/policies`,
        method: POST,
    }, // deprecated
    UPDATE_TIMEOFF_TYPE_POLICY: {
        url: `/api/${API_V1}/company/timeoffTypes/{0}/policies/{1}`,
        method: PUT,
    }, // deprecated
    DELETE_TIMEOFF_TYPE_POLICY: {
        url: `/api/${API_V1}/company/timeoffTypes/{0}/policies/{1}`,
        method: DELETE,
    }, // deprecated

    UPLOAD_TIMEOFF_TEMP_ATTACHMENT: {
        url: `/api/files/${API_V1}/file/temp/UPLOAD_LEAVE_ATTACHMENT`,
        method: POST,
    }, // command=UPLOAD_LEAVE_ATTACHMENT
    DOWNLOAD_TIMEOFF_TEMP_ATTACHMENT: {
        url: `/api/files/${API_V1}/file/temp/UPLOAD_LEAVE_ATTACHMENT`,
        method: GET,
    }, // command=UPLOAD_LEAVE_ATTACHMENT

    TIMEOFF_POLICY: { url: `/api/${API_V1}/company/timeoffPolicy`, method: GET }, // deprecated
    UPDATE_TIMEOFF_POLICY: { url: `/api/${API_V1}/company/timeoffPolicy`, method: PUT }, // deprecated
    NEXT_ANNUAL_CYCLE: {
        url: `/api/${API_V1}/company/timeoffPolicy/nextAnnualCycle/{0}/{1}`,
        method: GET,
    }, // deprecated //{0}=leaveFormula, {1}=annualCycle
    LEAVE_TYPES: { url: '/api' + '/leave/type/types/{0}', method: GET }, // {0}=officeId|empty, ?states=[0|1|0,1|empty]
    IS_NEED_HOURLY_FORM: { url: '/api/leave/balance/isNeedHourlyForm/{0}', method: GET }, // {0}=officeId

    // LEAVE APP
    LEAVE_APPROVERS: { url: `/api/leave/settings/approver`, method: GET },
    UPDATE_LEAVE_APPROVERS: { url: `/api/leave/settings/approver`, method: PUT },
    CREATE_LEAVE_POLICY: { url: `/api/${API_V2}/leave/setting/policy/{0}`, method: POST }, // {0}=officeId
    CHECK_TARGET_LEAVE_CYCLE: {
        url: `/api/${API_V2}/leave/setting/policy/targetLeaveCycle/{0}/{1}/{2}`,
        method: GET,
    }, // {0}=officeId, {1}=leavePolicyId, {2}=leaveCycle//timestamp
    LEAVE_POLICIES_ALL: { url: `/api/${API_V2}/leave/setting/policies`, method: GET },
    LEAVE_POLICIES: { url: `/api/leave/type/types/{0}`, method: GET }, // {0}=officeId
    LEAVE_POLICY: { url: `/api/${API_V2}/leave/setting/policy/{0}/{1}`, method: GET }, // {0}=officeId, {1}=leavePolicyId
    UPDATE_ENABLE_STATUS: {
        url: `/api/${API_V2}/leave/setting/policy/enableStatus/{0}/{1}`,
        method: PUT,
    }, // {0}=officeId, {1}=leavePolicyId
    UPDATE_LEAVE_POLICY: {
        url: `/api/${API_V2}/leave/setting/policy/{0}/{1}`,
        method: PUT,
    }, // {0}=officeId, {1}=leavePolicyId
    DELETE_LEAVE_POLICY: {
        url: `/api/${API_V2}/leave/setting/policy/{0}/{1}`,
        method: DELETE,
    }, // {0}=officeId, {1}=leavePolicyId
    CREATE_ENTITLEMENT_GROUP: {
        url: `/api/${API_V2}/leave/setting/policy/entitlementGroup/{0}/{1}`,
        method: POST,
    }, // {0}=officeId, {1}=leavePolicyId
    UPDATE_ENTITLEMENT_GROUP: {
        url: `/api/${API_V2}/leave/setting/policy/entitlementGroup/{0}/{1}/{2}`,
        method: PUT,
    }, // {0}=officeId, {1}=leavePolicyId {2}=entitlementGroupId
    DELETE_ENTITLEMENT_GROUP: {
        url: `/api/${API_V2}/leave/setting/policy/entitlementGroup/{0}/{1}/{2}`,
        method: DELETE,
    }, // {0}=officeId, {1}=leavePolicyId {2}=entitlementGroupId
    UPDATE_EARNED_LEAVE: {
        url: `/api/${API_V2}/leave/setting/policy/earnedLeave/{0}/{1}`,
        method: PUT,
    }, // {0}=officeId, {1}=leavePolicyId
    CHECK_WORKGROUP_SETTINGS_CHANGED: {
        url: `/api/${API_V1}/settings/{0}/offices/workgroup/WORK_GROUP_WORK_DAY/checkWorkGroupSettingsChanged`,
        method: GET,
    }, // {0}=companyId
    NOTICED_WORKGROUP_SETTINGS_CHANGED: {
        url: `/api/v1/settings/{0}/offices/workgroup/WORK_GROUP_WORK_DAY/{1}/noticed`,
        method: POST,
    }, // {0}=companyId {1}=workGroupFieldId
    GET_WORKGROUP_BY_USER: {
        url: `/api/v1/settings/{0}/offices/workgroup/users/{1}`,
        method: GET,
    }, // {0}=companyId {1}=userId

    // Calendar
    CALENDAR_FILTERS: { url: `/api/${API_V1}/calendar/filters`, method: GET },
    CALENDAR_EVENTS: { url: `/api/${API_V1}/calendar/events`, method: GET },
    CALENDAR_EVENTS_AND_HOLIDAYS: { url: `/api/${API_V1}/calendar/eventsAndHolidays`, method: GET },
    WORK_DAY: { url: `/api/${API_V1}/settings/{0}/offices/workgroup/workday/{1}`, method: GET }, // {0}=companyId {1}=userId
    GET_CALENDAR_FEED_INTEGRATIONS: {
        url: `/api/${API_V1}/company/integration/calendar/feed`,
        method: GET,
    },
    CREATE_CALENDAR_FEED_INTEGRATION: {
        url: `/api/${API_V1}/company/integration/calendar/feed`,
        method: POST,
    },
    UPDATE_CALENDAR_FEED_INTEGRATION: {
        url: `/api/${API_V1}/company/integration/calendar/feed`,
        method: PUT,
    },
    DELETE_CALENDAR_FEED_INTEGRATION: {
        url: `/api/${API_V1}/company/integration/calendar/feed`,
        method: DELETE,
    },

    // Goal
    GOALS: { url: `/api/${API_V1}/goals`, method: GET },
    GOAL: { url: `/api/${API_V1}/goals/{0}`, method: GET },
    TEAM_GOALS: { url: `/api/${API_V1}/goals/team`, method: GET },
    COMPANY_GOALS: { url: `/api/${API_V1}/goals/company`, method: GET },
    MANAGE_GOALS: { url: `/api/${API_V1}/goals/manage`, method: GET },
    CREATE_GOAL: { url: `/api/${API_V1}/goals`, method: POST },
    UPDATE_GOAL: { url: `/api/${API_V1}/goals/{0}`, method: PUT },
    SUBMIT_GOAL: { url: `/api/${API_V1}/goals/{0}/submit`, method: PUT },
    APPROVE_DECLINE_GOAL: { url: `/api/${API_V1}/goals/{0}/decide`, method: PUT },
    UPDATE_GOAL_PROGRESS: { url: `/api/${API_V1}/goals/{0}/progress`, method: PUT },
    UPDATE_GOAL_SCORE: { url: `/api/${API_V1}/goals/{0}/score`, method: PUT },
    DELETE_GOAL: { url: `/api/${API_V1}/goals/{0}`, method: DELETE },
    GOAL_TARGET_USERS: { url: `/api/${API_V1}/goals/review/target-users`, method: GET },
    DOWNLOAD_GOAL_REPORT: { url: `/api/${API_V1}/goals/report`, method: GET },

    // Payslip
    PAYSLIP_PERIODS: { url: `/api/${API_V2_2}/{0}/payroll/payslip/period`, method: GET },
    PAYSLIP: { url: `/api/${API_V2_2}/{0}/payroll/payslip/{1}/{2}`, method: GET },
    ADHOC_PAYROLL_PAYSLIP: {
        url: `/api/${API_V2_2}/{0}/payroll/payslip/{1}/{2}/{3}`,
        method: GET,
    },
    DOWNLOAD_PAYSLIP: {
        url: `/api/${API_V2_2}/{0}/payroll/payslip/{1}/{2}/download`,
        method: GET,
    },
    DOWNLOAD_ADHOC_PAYROLL_PAYSLIP: {
        url: `/api/${API_V2_2}/{0}/payroll/payslip/{1}/{2}/{3}/download`,
        method: GET,
    },

    // Office
    UPDATE_USER_OFFICE: {
        url: `/api/${API_V1}/people/profile/users/{0}/office`,
        method: PUT,
    }, // {0}=userId, param={"office": {"id" : 2}}
    OFFICES: { url: `/api/${API_V1}/company/offices`, method: GET },
    OFFICE: { url: `/api/${API_V1}/company/offices/{0}`, method: GET }, // {0}=officeId
    CREATE_OFFICE: { url: `/api/${API_V1}/company/offices`, method: POST },
    UPDATE_OFFICE: { url: `/api/${API_V1}/company/offices/{0}`, method: PUT },
    DELETE_OFFICE: { url: `/api/${API_V1}/company/offices/{0}`, method: DELETE },
    COUNTRIES: { url: `/api/${API_V1}/countries`, method: GET },
    SUB_DIVISIONS: { url: `/api/${API_V1}/countries/{0}/subdivisions`, method: GET }, // {0}=countryCode
    SUB_DIVISION_DISTRICTS: {
        url: `/api/${API_V1}/countries/{0}/subdivisions/{1}/districts`,
        method: GET,
    }, // {0}=countryCode {1}=subdivisionCode
    COUNTRY_TIMEZONE: { url: `/api/${API_V1}/countries/{0}/timezone`, method: GET }, // {0}=countryCode

    GET_WORKGROUPS_BY_OFFICE: {
        url: `/api/${API_V1}/settings/{0}/offices/{1}/workgroups/`,
        method: GET,
    }, // {0}=companyId, {1}=officeId
    // WORKGROUP
    CREATE_WORKGROUP: {
        url: `/api/${API_V1}/settings/{0}/offices/{1}/workgroup/`,
        method: POST,
    }, // {0}=companyId, {1}=officeId
    READ_WORKGROUP: {
        url: `/api/${API_V1}/settings/{0}/offices/workgroup/{1}`,
        method: GET,
    }, // {0}=companyId, {1}=workGroupId
    UPDATE_WORKGROUP: {
        url: `/api/${API_V1}/settings/{0}/offices/{1}/workgroup/{2}/{3}`,
        method: PUT,
    }, // {0}=companyId, {1}=officeId, {2}=workgroupId, {3}=formType(fieldType)
    DELETE_WORKGROUP: {
        url: `/api/${API_V1}/settings/{0}/offices/{1}/workgroup/{2}`,
        method: DELETE,
    }, // {0}=companyId, {1}=officeId, {2}=workGroupId
    READ_WORKGROUP_BY_HISTORY_ID: {
        url: `/api/${API_V1}/settings/{0}/offices/workgroup/history/{1}`,
        method: GET,
    }, // {0}=companyId, {1}=historyId

    // MANAGE PEOPLE
    GET_ALL_WORKGROUPS: { url: `/api/${API_V1}/settings/{0}/workgroups`, method: GET }, // {0}={companyId}
    TEAMS: { url: `/api/${API_V1}/people/teamMember/teams`, method: GET },
    RELOCATION_TEAM: { url: `/api/${API_V1}/people/teamMember/users`, method: PUT },
    USER: { url: `/api/${API_V1}/people/users/{0}`, method: GET },
    USER2: { url: `/api/${API_V2}/people/users/{0}`, method: GET },
    RESEND_INVITATION_EMAIL: {
        url: `/api/${API_V1}/onboarding/sendInvitationEmail/{0}`,
        method: POST,
    }, // {0}=user.id
    UPDATE_INVITATION_DATE: { url: `/api/${API_V1}/onboarding`, method: PUT },
    GET_APPROVER_INFO: { url: `/api/${API_V1}/approverInfo/{0}`, method: GET }, // {0}={userId}

    // COMPANY_SETTING
    COMPANY: { url: `/api/${API_V1}/company`, method: GET },
    COMPANY_LOGO_UPDATE: { url: `/api/${API_V1}/company/logoImage`, method: POST },
    UPDATE_COMPANY: { url: `/api/${API_V1}/company`, method: PUT },
    CREATE_COMPANY_TIMEOFF_TYPE: {
        url: `/api/${API_V1}/company/timeoffTypes`,
        method: POST,
    },
    UPDATE_COMPANY_TIMEOFF_TYPE: {
        url: `/api/${API_V1}/company/timeoffTypes/{0}`,
        method: PUT,
    },
    DELETE_COMPANY_TIMEOFF_TYPE: {
        url: `/api/${API_V1}/company/timeoffTypes/{0}`,
        method: DELETE,
    },
    COMPANY_HOLIDAYS: { url: `/api/${API_V1}/company/offices/{0}/holidays`, method: GET }, // 반드시 `?year='+searchYear`와 같이 쿼리를 붙여야 한다.
    CREATE_COMPANY_HOLIDAY: {
        url: `/api/${API_V1}/company/offices/{0}/holidays`,
        method: POST,
    },
    UPDATE_COMPANY_HOLIDAY: {
        url: `/api/${API_V1}/company/offices/{0}/holidays/{1}`,
        method: PUT,
    },
    DELETE_COMPANY_HOLIDAY: {
        url: `/api/${API_V1}/company/offices/{0}/holidays/{1}`,
        method: DELETE,
    },
    CREATE_COMPANY_GOAL_SETTER: {
        url: `/api/${API_V1}/company/permissions`,
        method: POST,
    },
    DELETE_COMPANY_GOAL_SETTER: {
        url: `/api/${API_V1}/company/permissions`,
        method: DELETE,
    },

    MAKE_UP_DAYS_YEARS: { url: `/api/${API_V1}/company/offices/{0}/makeupDays/years`, method: GET },
    MAKE_UP_DAYS: { url: `/api/${API_V1}/company/offices/{0}/makeupDays/{1}`, method: GET },
    UPDATE_MAKE_UP_DAYS: { url: `/api/${API_V1}/company/offices/{0}/makeupDays/{1}`, method: PUT },

    POSSIBLE_PARENT_TEAMS: {
        url: `/api/${API_V1}/people/teamMember/possibleParentTeams`,
        method: GET,
    },
    CREATE_TEAM: { url: `/api/${API_V1}/people/teamMember/teams`, method: POST },
    UPDATE_TEAM: { url: `/api/${API_V1}/people/teamMember/teams/{0}`, method: PUT },
    DELETE_TEAM: { url: `/api/${API_V1}/people/teamMember/teams/{0}`, method: DELETE },

    // Balance
    BALANCES: { url: `/api/${API_V1}/people/users/{0}/timeoffBalances`, method: GET },
    UPDATE_BALANCE: {
        url: `/api/${API_V1}/people/users/{0}/timeoffBalances/{1}`,
        method: PATCH,
    }, // deprecated
    UPDATE_BALANCE_BULK_UPDATE: {
        url: `/api/${API_V1}/people/timeoffBalances/bulk/update`,
        method: POST,
    },
    DOWNLOAD_PEOPLE_BALANCE: {
        url: `/api/${API_V1}/people/timeoffBalances/bulk/update`,
        method: GET,
    },
    LEAVE_BALANCES: { url: '/api/' + 'leave/balance/balances/{0}/{1}', method: GET }, // {0} = officeId, {1} = userId
    UPDATE_LEAVE_BALANCE: { url: '/api/' + 'leave/balance/{0}', method: PATCH }, // {0} = leaveBalanceId
    LEAVE_BALANCE_HISTORIES: { url: '/api/' + 'leave/balance/history/{0}', method: GET }, // {0} = userId

    // ACCOUNT SETTING
    UPDATE_PROFILE_IMAGE: { url: `/api/${API_V2}/people/profile/image/{0}`, method: POST },
    CHANGE_PASSWORD: { url: `/api/${API_V1}/account/password`, method: PATCH },
    NOTIFICATION_SETTINGS: { url: `/api/${API_V1}/notification/settings`, method: GET },
    UPDATE_NOTIFICATION_SETTING: {
        url: `/api/${API_V1}/notification/settings/{0}/{1}`,
        method: PUT,
    }, // {0}:notification_method, {1}:notification_type
    ACCOUNT_LANGUAGE: { url: `/api/${API_V1}/account/language`, method: GET },
    UPDATE_ACCOUNT_LANGUAGE: { url: `/api/${API_V1}/account/language`, method: PUT },

    // SWITCH ACCOUNT
    PARTNER_AGENT_MAP: { url: `/api/${API_V1}/partner/agent/mappings`, method: GET },
    AUTH_SWITCH_ACCOUNT: { url: `/api/auth/${API_V1}/switch`, method: GET }, // ?agentMappingId=

    // PROFILE
    UPDATE_PROFILE_USER: { url: `/api/${API_V1}/people/profile/{0}/user`, method: PUT },
    UPDATE_PROFILE_PRIVATE_USER: {
        url: `/api/${API_V1}/people/profile/{0}/userPrivate`,
        method: PUT,
    },
    UPDATE_PROFILE_EMERGENCY_CONTACT: {
        url: `/api/${API_V1}/people/profile/{0}/emergencyContact`,
        method: PUT,
    },

    CREATE_PROFILE_QUALIFICATION: {
        url: `/api/${API_V1}/people/profile/{0}/userQualification`,
        method: POST,
    },
    UPDATE_PROFILE_QUALIFICATION: {
        url: `/api/${API_V1}/people/profile/{0}/userQualification/{1}`,
        method: PUT,
    },
    DELETE_PROFILE_QUALIFICATION: {
        url: `/api/${API_V1}/people/profile/{0}/userQualification/{1}`,
        method: DELETE,
    },

    CREATE_PROFILE_CERTIFICATE: {
        url: `/api/${API_V1}/people/profile/{0}/userCertificates`,
        method: POST,
    },
    UPDATE_PROFILE_CERTIFICATE: {
        url: `/api/${API_V1}/people/profile/{0}/userCertificates/{1}`,
        method: PUT,
    },
    DELETE_PROFILE_CERTIFICATE: {
        url: `/api/${API_V1}/people/profile/{0}/userCertificates/{1}`,
        method: DELETE,
    },

    FAMILY_RELATIONSHIPS: {
        url: `/api/${API_V1}/selectionItem/profile/userFamily/relationships`,
        method: GET,
    },

    DISABILITIES: {
        url: `/api/${API_V1}/selectionItem/profile/disabilities`,
        method: GET,
    },
    EXCEPTION_IDENTITY: {
        url: `/api/${API_V1}/selectionItem/profile/exceptionalIdentities`,
        method: GET,
    },
    BANKS: { url: `/api/${API_V1}/selectionItem/banks`, method: GET }, // ?countryCode={countryCode}
    PAYMENT_TYPES: {
        url: `/api/${API_V1}/selectionItem/profile/paymentTypes`,
        method: GET,
    },
    SALARY_BASES: { url: `/api/${API_V1}/selectionItem/profile/salaryBases`, method: GET },

    CREATE_PROFILE_FAMILY: {
        url: `/api/${API_V1}/people/profile/{0}/userFamilies`,
        method: POST,
    },
    UPDATE_PROFILE_FAMILY: {
        url: `/api/${API_V1}/people/profile/{0}/userFamilies/{1}`,
        method: PUT,
    },
    DELETE_PROFILE_FAMILY: {
        url: `/api/${API_V1}/people/profile/{0}/userFamilies/{1}`,
        method: DELETE,
    },

    UPDATE_PROFILE_SUPER_PRIVATE_USER: {
        url: `/api/${API_V1}/people/profile/{0}/userSuperPrivate`,
        method: PUT,
    },

    CREATE_PROFILE_COMPENSATION: {
        url: `/api/${API_V1}/people/profile/{0}/userCompensations`,
        method: POST,
    },
    UPDATE_PROFILE_COMPENSATION: {
        url: `/api/${API_V1}/people/profile/{0}/userCompensations/{1}`,
        method: PUT,
    },
    DELETE_PROFILE_COMPENSATION: {
        url: `/api/${API_V1}/people/profile/{0}/userCompensations/{1}`,
        method: DELETE,
    },

    CREATE_PROFILE_DISCIPLINARY_ACTION: {
        url: `/api/${API_V1}/people/profile/{0}/userDisciplinaryActions`,
        method: POST,
    },
    UPDATE_PROFILE_DISCIPLINARY_ACTION: {
        url: `/api/${API_V1}/people/profile/{0}/userDisciplinaryActions/{1}`,
        method: PUT,
    },
    DELETE_PROFILE_DISCIPLINARY_ACTION: {
        url: `/api/${API_V1}/people/profile/{0}/userDisciplinaryActions/{1}`,
        method: DELETE,
    },

    EMPLOYEEMENT_TYPES: { url: `/api/${API_V1}/people/employmentTypes`, method: GET },

    // ONBOARDING
    ONBOARDING: { url: `/api/${API_V1}/onboarding`, method: POST },
    UPLOAD_EMPLOYEE_BULK_UPLOAD: {
        url: `/api/${API_V1}/people/users/bulk/add`,
        method: POST,
    },

    REPORT_ENROLL: { url: `/api/${API_V1}/report/enroll`, method: POST },
    PEOPLE_REPORT_DOWNLOAD: { url: `/api/${API_V1}/report/people/{0}.{1}`, method: GET },
    PEOPLE_REPORT_DIRECT_DOWNLOAD: {
        url: `/api/${API_V1}/report/people.{0}`,
        method: GET,
    },
    REPORT_PEOPLE_COLUMNS: { url: `/api/${API_V1}/report/people/columns`, method: GET },

    // GROUP
    GROUPS: { url: `/api/${API_V1}/groups`, method: GET },
    CREATE_GROUP: { url: `/api/${API_V1}/groups`, method: POST },
    UPDATE_GROUP: { url: `/api/${API_V1}/groups/{0}`, method: PUT },
    DELETE_GROUP: { url: `/api/${API_V1}/groups/{0}`, method: DELETE },

    // NOTIFICATION
    NOTIFICATIONS: { url: `/api/${API_V1}/notification/news`, method: GET },
    CLEAR_NOTIFICATION: { url: `/api/${API_V1}/notification/news/clear/{0}`, method: PUT },

    // BILLING
    START_FREE_TRIAL: { url: `/api/${API_V2}/billing/startFreeTrial`, method: POST }, // ?billingServices=PAYROLL
    UNSUBSCRIBE: { url: `/api/${API_V2}/billing/unsubscribe`, method: PUT }, // ?billingServices=PAYROLL
    START_SUBSCRIPTION: { url: `/api/${API_V2}/billing/startSubscription`, method: POST },
    GET_COMPANY_BILLING_INFO: { url: `/api/${API_V1}/billing/company/{0}`, method: GET }, // {0}=companyId
    CHANGE_PLAN: { url: `/api/${API_V2}/billing/changePlan`, method: PUT },
    SET_PAYMENT_METHOD: { url: `/api/${API_V1}/billing/paymentMethod`, method: PUT },
    ADD_MODIFY_CREDIT_CARD: { url: `/api/${API_V1}/billing/creditCard`, method: PUT },
    DELETE_CREDIT_CARD: { url: `/api/${API_V1}/billing/creditCard`, method: DELETE },
    TEST_GRANT_FREE_TRIAL: { url: `/api/${API_V1}/test/grantFreeTrial`, method: GET }, // ?billingServices=PAYROLL_SG
    SURVEY: { url: `/api/${API_V1}/billing/survey/{0}`, method: GET },
    SURVEY_PROPERTIES: {
        url: `/api/${API_V1}/billing/survey/selectProperties`,
        method: GET,
    },
    SEND_SURVEY: { url: `/api/${API_V1}/billing/survey`, method: POST },

    SWITCH_PLAN: { url: `/api/${API_V1}/billing/switchPlan`, method: GET }, // ?billingService=PAYROLL&billingGrade=CHARGED_MONTHLY&return_url=https://secure.swingvy.com...
    BILLING_INVOICES: { url: `/api/${API_V1}/billing/invoices`, method: GET },
    BILLING_HISTORIES: { url: `/api/${API_V1}/billing/history`, method: GET },
    BILLING_TOTAL_SUMMARY: {
        url: `/api/${API_V1}/billing/billingTotalSummary`,
        method: GET,
    }, // ?billingService=PAYROLL_MY,PREMIUM&billingGrade=CHARGED_MONTHLY 또는 ?billingService=PAYROLL_MY,PREMIUM&billingGrade=CHARGED_YEARLY
    PRICE_MAP: { url: `/api/${API_V1}/billing/priceMap`, method: GET },

    // Claims
    REFRESH_TOKEN: { url: `/api/auth/${API_V1}/refresh`, method: 'POST' },
    CURRENCIES: { url: `/api/claims/${API_V1}/claims/currency`, method: GET },
    CLAIMS_CUTOFF_DATE: {
        url: `/api/claims/${API_V1}/claims/settings/cutoffdate`,
        method: GET,
    },
    CLAIMS_COMPANY_CURRENCY: {
        url: `/api/claims/${API_V1}/claims/settings/currency`,
        method: GET,
    },
    CLAIMS_APPROVER: {
        url: `/api/claims/${API_V1}/claims/settings/approver/user`,
        method: GET,
    },
    CLAIMS_REPORT: { url: `/api/claims/${API_V1}/claims/report/{0}`, method: GET },
    CLAIMS_REPORT_CUSTOM_FIELD: {
        url: `/api/claims/${API_V1}/claims/type/custom-field`,
        method: GET,
    }, /// query: claimReportId={claimReportId})
    CLAIMS_REPORT_CUSTOM_FIELD_APPROVALS: {
        url: `/api/claims/${API_V1}/claims/type/custom-field/approvals`,
        method: GET,
    }, // query: claimReportId={claimReportId})
    CLAIMS_REPORTS: {
        url: `/api/claims/${API_V2}/claims/report/employee/{0}`,
        method: GET,
    }, // {0}=userId
    CLAIMS_REPORT_TYPE_CYCLE_LIMITS: {
        url: `/api/claims/${API_V1}/limit/claim/report/{0}`,
        method: GET,
    },
    CLAIM_TYPES: { url: `/api/claims/${API_V1}/claims/type`, method: GET },
    CLAIM_TYPE_OF_USER: { url: `/api/claims/${API_V1}/claims/type/user`, method: GET },
    CLAIM_TYPE_OF_APPROVER: { url: `/api/claims/${API_V1}/claims/type/approver`, method: GET },
    CREATE_CLAIM_REPORT: { url: `/api/claims/${API_V1}/claims/report`, method: POST },
    UPDATE_CLAIM_REPORT: { url: `/api/claims/${API_V1}/claims/report`, method: PUT },
    UPDATE_CLAIM_REPORT_STATUS: {
        url: `/api/claims/${API_V1}/claims/report/status`,
        method: PUT,
    },
    DELETE_CLAIM_REPORT: {
        url: `/api/claims/${API_V1}/claims/report/{0}`,
        method: DELETE,
    }, // {0}=claimReportId
    CREATE_CLAIM_ITEM: { url: `/api/claims/${API_V1}/claims/item`, method: POST },
    UPDATE_CLAIM_ITEM: { url: `/api/claims/${API_V1}/claims/item`, method: PUT },
    DELETE_CLAIM_ITEM: { url: `/api/claims/${API_V1}/claims/item/{0}`, method: DELETE }, // {0}=claimItemId
    UPLOAD_CLAIM_ATTACHMENT: {
        url: `/api/files/${API_V1}/file/temp/TEMP_UPLOAD_CLAIM_ATTACHMENT`,
        method: POST,
    },
    REQUESTERS_APPROVALS: {
        // /api/v2/claims/report/allRequesters
        url: `/api/claims/${API_V2}/claims/report/allRequesters`,
        method: GET,
    },
    ALL_APPROVAL_CLAIM_REPORTS: {
        url: `/api/claims/${API_V2}/claims/approval/allapprovals`,
        method: POST,
    },
    PENDING_APPROVAL_CLAIM_REPORTS: {
        url: `/api/claims/${API_V2}/claims/approval/pendingapprovals`,
        method: POST,
    },

    // MDEC program
    MDEC_STOP_NOTIFY: { url: `/api/${API_V1}/mdec/stopNotify`, method: PUT },

    // Startup program
    STARTUP_PROGRAM_STOP_NOTIFY: {
        url: `/api/${API_V1}/startup-program/stopNotify`,
        method: PUT,
    },

    // CNY Promotion
    HOME_INFORM_MODAL: { url: `/api/${API_V1}/home/inform/modal/{0}`, method: GET }, // {0}=companyId
    HOME_INFORM_HEADER: { url: `/api/${API_V1}/home/inform/header/{0}`, method: GET }, // {0}=companyId

    // TESTING
    INITIALIZE_DATA: { url: `/api/${API_V1}/test/initialize`, method: GET },

    // XERO
    GET_XERO_AUTH_TOKEN: {
        url: `/api/${API_V1}/company/integration/setting/xero/config/auth`,
        method: GET,
    },
    DELETE_XERO_AUTH_TOKEN: {
        url: `/api/${API_V1}/company/integration/setting/xero/config/auth`,
        method: DELETE,
    },
    PATCH_XERO_AUTH_TOKEN: {
        url: `/api/${API_V1}/company/integration/setting/xero/config/auth`,
        method: PATCH,
    },
    GET_XERO_CONFIG_ORG: {
        url: `/api/${API_V1}/company/integration/setting/xero/config/org`,
        method: GET,
    },
    GET_XERO_CONFIG_SETTING: {
        url: `/api/${API_V1}/company/integration/setting/xero/config/setting`,
        method: GET,
    },
    PUT_XERO_CONFIG_SETTING: {
        url: `/api/${API_V1}/company/integration/setting/xero/config/setting`,
        method: PUT,
    },
    GET_XERO_ACCOUNTS: {
        url: `/api/${API_V1}/company/integration/setting/xero/item/accounts`,
        method: GET,
    },
    GET_XERO_CONTACTS: {
        url: `/api/${API_V1}/company/integration/setting/xero/item/contacts`,
        method: GET,
    },
    GET_XERO_TRACKING_CODE: {
        url: `/api/${API_V1}/company/integration/setting/xero/item/trackingCategories`,
        method: GET,
    },
    GET_XERO_AMOUNT_TYPE: {
        url: `/api/${API_V1}/company/integration/setting/xero/item/amountTypes`,
        method: GET,
    },
    GET_XERO_AMOUNT_TYPE_MAP: {
        url: `/api/${API_V1}/company/integration/setting/xero/mapping/amountType`,
        method: GET,
    },
    PUT_XERO_AMOUNT_TYPE_MAP: {
        url: `/api/${API_V1}/company/integration/setting/xero/mapping/amountType`,
        method: PUT,
    },
    PATCH_XERO_AMOUNT_TYPE_MAP: {
        url: `/api/${API_V1}/company/integration/setting/xero/mapping/amountType`,
        method: PATCH,
    },
    GET_XERO_TEAM_MAP: {
        url: `/api/${API_V1}/company/integration/setting/xero/mapping/team`,
        method: GET,
    },
    PUT_XERO_TEAM_MAP: {
        url: `/api/${API_V1}/company/integration/setting/xero/mapping/team`,
        method: PUT,
    },
    GET_XERO_SETTING_STATUS: {
        url: `/api/${API_V1}/company/integration/setting/xero/status`,
        method: GET,
    },
    GET_EXPERIMENTAL_FLAGS: { url: `/api/${API_V1}/experiment/featureFlags`, method: GET },

    REFERRAL_PROGRAM_REFERRAL_URL: { url: `/api/${API_V2}/referral/url`, method: GET },
    REFERRAL_PROGRAM_REFERRAL_INVITATION: {
        url: `/api/${API_V2}/referral/invitation`,
        method: POST,
    },
    REFERRAL_PROGRAM_HISTORY: { url: `/api/${API_V2}/referral/history`, method: GET },
    // StaffAny
    GET_STAFFANY_CONFIG: {
        url: `/api/${API_V1}/company/integration/setting/staffany/config`,
        method: GET,
    },
    POST_STAFFANY_CONFIG: {
        url: `/api/${API_V1}/company/integration/setting/staffany/config`,
        method: POST,
    },
    PUT_STAFFANY_STATUS: {
        url: `/api/${API_V1}/company/integration/setting/staffany/config/status`,
        method: PUT,
    },
    GET_STAFFANY_MAPPING: {
        url: `/api/${API_V1}/company/integration/setting/staffany/mapping`,
        method: GET,
    },
    PUT_STAFFANY_MAPPING: {
        url: `/api/${API_V1}/company/integration/setting/staffany/mapping`,
        method: PUT,
    },
    GET_STAFFANY_STAFFS: {
        url: `/api/${API_V1}/company/integration/setting/staffany/staffs`,
        method: GET,
    },
    // Shiftee
    GET_SHIFTEE_CONFIG: {
        url: `/api/${API_V1}/company/integration/setting/shiftee/config`,
        method: GET,
    },
    POST_CONNECT_SHIFTEE: {
        url: `/api/${API_V1}/company/integration/setting/shiftee/config`,
        method: POST,
    },
    PATCH_CONNECT_SHIFTEE: {
        url: `/api/${API_V1}/company/integration/setting/shiftee/config`,
        method: PATCH,
    },
    DOWNLOAD_LEAVE_ACCRUAL_REPORT: {
        url: `/api/leave/report/accrual`,
        method: GET,
    },
};
